<template>
  <div class="workspace">
    <!-- <img class="bg" src=""> -->
    <div class="title">
      <p class="worktitle">我们的办公室</p>
      <p class="slogan">游戏无国界。无论玩家住在何处，我们的团队都会尽力保证他们得到最佳的游戏体验。</p>
    </div>
    <div class="showPic">
      <img class="pic1" src="@/assets/image/pc/about/989A4768.jpg.png">
      <img class="pic2" src="@/assets/image/pc/about/989A2554.jpg">
      <img class="pic3" src="@/assets/image/pc/about/公司环境_018.jpg.png">
      <img class="pic4" src="@/assets/image/pc/about/989A5584.jpg.png">
    </div>
  </div>
</template>
<script>
export default ({
  name: "WorkSpace"
})
</script>

<style lang="scss" scoped>
.workspace{
  width: 1920px;
  height: 1285px;
  .bg{
    position: absolute;
    width: 1920px;
    height: 1285px;
  }
  .title{
    margin-top: 65px;
    p{
      text-align: center;
    }
    .worktitle{
      font-size: 64px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #292826;
      line-height: 83px;
    }
    .slogan{
      margin-top: 30px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #292826;
      line-height: 36px;
    }
  }
  .showPic{
    position: relative;
    .pic1{
      position: absolute;
      left: 384px;
      width: 555px;
      height: 370px;
      top: 112px;
    }
    .pic2{
      position: absolute;
      left: 993px;
      top: 40px;
      width: 551px;
      height: 367px;
    }
    .pic3{
      width: 343px;
      position: absolute;
      height: 436px;
      left: 596px;
      top: 577px;
    }
    .pic4{
      position: absolute;
      width: 580px;
      height: 370px;
      left: 993px;
      top: 523px;
    }
  }
}
</style>
