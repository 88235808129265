<template>
  <div class="about">
    <Head />
    <Cheer />
    <Story />
    <Mission />
    <WorkSpace />
    <Floor />
  </div>
</template>
<script>
import Head from '@/views/Head'
import Floor from '@/views/Floor'
import Cheer from './Cheer'
import Story from './Story'
import Mission from './Misson'
import WorkSpace from './WorkSpace'

export default ({
  name: "About",
  components: {
    Head,
    Floor,
    Cheer,
    Story,
    Mission,
    WorkSpace
  }
})
</script>

<style lang="scss" scoped>
.about{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
</style>
