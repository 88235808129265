<template>
  <div class="story">
    <p class="storytitle">我们的故事</p>
    <div class="storycontent">
      <p class="content">上海域起网络科技有限公司成立于 2013年，始终秉承“通过科技和艺术改变世界”的文化使命，是一家集游戏研发和发行于一体的高新技术企业，2020年被评定为浦东新区企业研发机构、浦东新区高成长性总部，并在2021年成为上海市第一批重点机构中的一员。</p>
      <p class="content">公司拥有5A级办公环境，积极推崇硅谷文化。公司规模近400人，核心成员曾服务于华为、腾讯、网易、莉莉丝等多家知名企业。公司自主研发并发行了《GG游戏大厅》、《Garden Affairs》等多款游戏，并于近年分别独家代理了以二次元“东方Project”为原型的二次创作手游《东方LostWord》、由国内知名团队研发的Match-3手游《Home Makeover》等，在全球拥有上亿注册用户。</p>
      <p class="content">通过自主研发、发行与代理发行并行，域起网络未来将开拓更多领域，进一步完善公司产业线布局，立足全球化视野，开拓产业发展战略新步伐。</p>
    </div>

  </div>
</template>
<script>
export default ({
  name: "Story"
})
</script>

<style lang="scss" scoped>
.story{
  height: 580px;
  .storytitle{
    margin-top: 130px;
    text-align: center;
    width: 1920px;
    font-size: 64px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #292826;
    line-height: 83px;
  }
  .storycontent{
    height: 272px;
    width: 847px;
    margin: 32px auto;
    .content{
      text-align: left;
      text-indent: 40px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #292826;
      line-height: 36px;
    }
  }
}
</style>
