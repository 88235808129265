<template>
  <div class="mission">
    <p class="title">我们的愿景与使命</p>
    <div class="points">
      <img src="@/assets/image/pc/about/愿望.jpg.png">
      <ul>
        <li />
        <li class="change">
          <p class="ttl">愿景</p>
          <p class="ct">成为世界级的游戏公司</p>
        </li>
        <li class="change">
          <p class="ttl">使命</p>
          <p class="ct">通过科技和艺术的结合，<br>创造出用户喜爱的产品</p>
        </li>
        <li class="change">
          <p class="ttl">价值观</p>
          <p class="ct">以用户为中心；<br>以奋斗者为本；<br>长期坚持艰苦奋斗</p>
        </li>
        <li />
      </ul>
    </div>
  </div>
</template>
<script>
export default ({
  name: "Mission"
})
</script>

<style lang="scss" scoped>
.mission{
  background: #E7E6E3;
  width: 1920px;
  height: 1071px;
  .title{
    position: relative;
    top: 77px;
    text-align: center;
    font-size: 64px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #292826;
    line-height: 83px;
  }
  img{
    position: absolute;
    width: 1920px;
    height: 848px;
  }
  .points{
    position: relative;
    margin-top: 139px;
    width: 1920px;
    height: 849px;
    ul{
      position: relative;
      margin: 0 auto;
      width: 1920px;
      display: flex;
      justify-content: space-evenly;
      .change:hover{
        background: rgba(0,0,0,0.6);
      }
      li{
        z-index: 999;
        width: 480px;
        height: 849px;
        // background: #000000;
        background: rgba(0,0,0,0.35);
        border-left: 1px solid #FFFFFF;
        // opacity: 0.35;
        .ttl{
          margin-top: 416px;
          margin-left: 30px;
          font-size: 56px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 93px;
        }
        .ct{
          font-size: 19px;
          margin-left: 29px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>
