<template>
  <div class="cheer">
    <img class="bg" src="@/assets/image/pc/about/头图.jpg.png">
    <p class="cheer_en">THE BEST TEAMS<br>MAKE THE BEST GAMES</p>
    <p class="cheer_zh">最好的团队制作最好的游戏</p>
  </div>
</template>
<script>
export default ({
  name: "Cheer"
})
</script>

<style lang="scss" scoped>
.cheer{
  width: 1920px;
  height: 452px;
  .bg{
    width: 1920px;
    height: 452px;
    position: absolute;
  }
  p{
    position: absolute;
    text-align: center;
    width: 1920px;
  }
  .cheer_en{
    margin-top: 190px;
    font-size: 44px;
    font-family: Poppins;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 45px;
    opacity: 0.76;
  }
  .cheer_zh{
    margin-top: 285px;
    font-size: 35px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 46px;
  }
}
</style>
